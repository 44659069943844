import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect, useDispatch, useSelector, } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { TouchableOpacity, FlatList, View, Text } from 'react-native-web';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Avatar, Button, Input, Modal, notification, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
// import $, { type } from "jquery";
import indicilogo from "../assets/indicilogo.png";
import getUserId from '../../shared/core/GetToken';
import { AuthContext } from "../../shared/core/context";
import { LogoutUser } from "../../shared/actions/AuthActions";
import { getProxyAccessMenu } from "../../shared/actions/ProxyMenuActions";
import { fetchProfileImageFromApi } from "../../shared/actions/GetProfileImageAction";
import Settings from "../../shared/config/Settings";
import { getProxyListApi } from '../../shared/actions/ProxyActions';
import { fetchProfileFromApi } from "../../shared/actions/ProfileActions"
import profileplace from "../assets/profileplace.png";
import { fetchPractisefromAPI } from "../../shared/actions/PractiseAction";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { FormatAMPM, FormateUTCTime, convertUTCToLocalTime, GetAppointmentDate, convertLocalTimeToUTCTime, displayTime } from '../../shared/core/datehelper';
import { fetchApplicationSettingFromApi } from '../../shared/actions/GetApplicationSettingAction';
import GetAppSettings from '../../shared/core/GetAppSettings';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';
const Header = props => {
    // const openMenu = () => {
    //     var element = document.getElementById("myphonemenu");
    //     //element.classList.add("sidebar-collpased");
    // }



    const { signOut } = React.useContext(AuthContext);
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const { Practise } = useSelector(state => state.PracticeOrganization);
    const { Balance, isLoadoing } = useSelector(state => state.UserAccountBalance);

    const { Proxy, isGettlistPrpxy } = useSelector(state => state.ProxyUsers);
    const { Profile, isFetching } = useSelector(state => state.UserProfile);
    const { ProfileImage } = useSelector(state => state.ProfileImage);
    const [currentUser, setCurrentUser] = React.useState('');
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [logoutConfirmModalOpen, setLogoutConfirmModalOpen] = React.useState(false);
    const [indiciHelpModalVisible, setIndiciHelpModalVisible] = useState(false);

    const [menuView, setMenuView] = useState(false);

    const [helpModalSubject, setHelpModalSubject] = useState("");
    const [helpModalSubjectErrMsg, setHelpModalSubjectErrMsg] = useState("");
    const [helpModalBody, setHelpModalBody] = useState("");
    const [helpModalBodyErrMsg, setHelpModalBodyErrMsg] = useState("");
    const [appSettings, setAppSettings] = useState({});
    const apiDispatcher = useDispatch()
    useEffect(() => {

        GetAppSettings().then((info) => {

            const set = JSON.parse(info)
            setAppSettings(set)
        })
        apiDispatcher(actionAPI.GetAccountBalanceAPI())
    }, [])
    let his = useNavigate();

    const useWindowSize = () => {

        const [windowSize, setWindowSize] = useState([0, 0])
        const updateWindowSize = () => {
            if (window.innerWidth > 993) {
                document.getElementById("main-sidebar").style.display = 'flex'
                document.getElementsByClassName("page-content-wrapper")[0].style.marginLeft = '224px'

                // $('.sidebar').css('display', 'flex');
                // $('.page-content-wrapper').css('margin-left', '224px')
            }
            else {
                document.getElementById("main-sidebar").style.display = 'none'
                document.getElementsByClassName("page-content-wrapper")[0].style.marginLeft = '0px'

                // $('.sidebar').css('display', 'none');
                // $('.page-content-wrapper').css('margin-left', '0')
            }
            setWindowSize([window.innerWidth, window.innerHeight])
        }
        useLayoutEffect(() => {
            window.addEventListener('resize', updateWindowSize);
            updateWindowSize();

            return () => window.removeEventListener('resize', updateWindowSize);
        }, [])
        return [windowSize[0], windowSize[1]]
    }

    const [width, height] = useWindowSize()

    const logoutUser = () => {



        getUserId().then((userInfo) => {
            if (userInfo !== 'none') {

                const item = JSON.parse(userInfo);


            setLogoutConfirmModalOpen(false)
            var myHeaders = new Headers();
            myHeaders.append("token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Logout", requestOptions)
                .then(response => response.json())
                .then(result => {

                    if (result.StatusCode == 1) {

                        apiDispatcher(actionAPI.LogoutUser())
                        apiDispatcher(actionAPI.refreshTokenValidatorData())
                        signOut();
                        his('/', { replace: true })
                        // window.location.reload();
                    } else {
                        apiDispatcher(actionAPI.LogoutUser())
                        apiDispatcher(actionAPI.refreshTokenValidatorData())

                        signOut();
                        his('/', { replace: true })
                        // window.location.reload();
                    }
                    window.location.reload();
                })
                .catch(error => {

                    // alert("Check your network connection.")
                });
            }
            else {
                window.location.reload();
            }
        })


        /// window.location.href = '/';
    }

    useEffect(() => {
        getCurrentUserId()
        getProfileInfo()
        apiDispatcher(actionAPI.GetProxyUsersAPI())
        getPatientName();
        apiDispatcher(actionAPI.GetUserProfileAPI())

    }, [])



    const getProfileInfo = () => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            // name = item.parentname;

            // setName(item.parentname)
            setName(item.username)
            let caughtEmail = false
            setEmail(item.email?.filter((email, index) => {
                if (email && !caughtEmail) {
                    caughtEmail = true
                    return email
                }
            }))
            setName((state) => {
                return state;
            });
            setEmail((state) => {
                return state;
            });
        })
        return name;
    }
    useEffect(() => {
        if (Profile || Practise) {
            getProfileInfo()
        }
    }, [Profile, Practise])

    const getCurrentUserId = () => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            // name = item.parentname;

            setCurrentUser(item.userid)
            setCurrentUser((state) => {
                return state;
            });
        })
        return currentUser;
    }


    const requestFamilyMemberPortalLoginCall = (parentId, proxyId, proxyName) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Tokeninfo", parentId)
            myHeaders.append("Content-Type", "application/json")
            var raw = JSON.stringify({
                ParentPatientID: parentId,
                PatientID: proxyId,
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };

            fetch(Settings.apiUrl + "MFA/RequestFamilyMemberPortalLogin", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result?.length > 0) {

                        _setDataState(proxyId, proxyName, proxyId)
                    } else {
                        openNotificationWithIcon("error", "Some error occured. Please try again")
                    }

                })
                .catch(error => { })
        })
    }
    const _setDataState = async (proxyUserId, proxyUserName, proxyUpdateId) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            _saveData(item.token, proxyUserId, item.email, "Yes", proxyUserName, item.parentid, proxyUpdateId, item.parentname, item.pictureID, item.username)
        })

    };

    const _saveData = async (token, proxyUserId, pemail, isProxy, proxyUserName, parentid, proxyid, parentname, pictureID, username = "") => {
        await AsyncStorage.removeItem('@userToken');
        try {
            const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "proxy": isProxy, "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentname, "patientPictureID": pictureID, "username": username });

            await AsyncStorage.setItem('@userToken', jsonValue)
            loadNext();
        } catch (error) {
            // Error saving data
        }
    }


    const loadNext = () => {
        window.location.reload();
    }
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age + " years";
    }
    let patientName = ''

    const [proxyName, setProxyName] = useState();
    const getPatientName = () => {

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


            if (item.userid === item.parentid) {
                patientName = item.username// "Self"
                setProxyName(item.username);
                setProxyName((state) => {
                    return state;
                });
            }
            else {
                patientName = item.username

                setProxyName(item.username);
                setProxyName((state) => {
                    return state;
                });
            }

        })

        return patientName;
    }

    const changeClasses = () => {
        if (menuView) {
            //hide themenu
            document.getElementById("main-sidebar").style.display = 'none'
            document.getElementsByClassName("page-content-wrapper")[0].style.marginLeft = '0px'

            // $('.sidebar').css('display', 'none');
            // $('.page-content-wrapper').css('margin-left', '0')
            setMenuView(false)
        } else {
            //show the menu
            document.getElementById("main-sidebar").style.display = 'flex'
            document.getElementsByClassName("page-content-wrapper")[0].style.marginLeft = '0px'

            setMenuView(true)
            // $('.sidebar').css('display', 'flex');
            // $('.page-content-wrapper').css('margin-left', '0px')
        }
    }
    const [isSmallDeviceDiv, setIsSmallDeviceDiv] = useState()
    const validateHelpData = () => {
        let errCaught = false
        if (helpModalSubject.trim() == "") {
            setHelpModalSubjectErrMsg("Subject is Mandatory")
            errCaught = true
        }
        if (helpModalBody.trim() == "") {
            setHelpModalBodyErrMsg("Body is Mandatory")
            errCaught = true
        }
        return errCaught
    }
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };
    const handleHelpSubmit = () => {
        if (validateHelpData()) {
            return
        }
        let raw = {
            "PatientID": getCurrentUserId(),        //send this empty
            "EmailAddress": "",   //email address
            "UID": "",       //NHI number
            "FirstName": "",     //first name
            "FamilyName": "",  //family name
            "DOB": "",       //date of birth
            "MessageSubject": helpModalSubject,   //Message Subject
            "MessageBody": helpModalBody    //body goes here
        }
        var myHeaders = new Headers();
        myHeaders.append("Token", JSON.parse(localStorage.getItem("@userToken")).token);
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(raw),
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${Settings.apiUrl}SendHelpMessage`, requestOptions)
            .then(response => response.json())
            .then(result => {
                openNotificationWithIcon("success", result)

                setIndiciHelpModalVisible(false)
                setHelpModalBody("")
                setHelpModalSubject("")
            }
            )

            .catch(error => openNotificationWithIcon("error", error));
    }
    return (

        <nav className="t-header">
            <div key="dv1" className="t-header-brand-wrapper bg-white">
                <a href="/">
                    <img className="logo" src={indicilogo} alt="Logo" />
                    <img className="logo-mini" src={indicilogo} alt="Logo" />
                </a>

            </div>
            <div key="dv2" className="t-header-content-wrapper bg-white">
                <div className="t-header-content position-relative">
                    <div className='small-header-icons'>
                        <button onClick={() => changeClasses()} className="t-header-toggler t-header-mobile-toggler d-block d-lg-none header-menu-icon">
                            <img src='assets/images/menu-ICON.svg' alt='menu' />
                        </button>
                        <button className=" header-menu-icon" onClick={() => setIsSmallDeviceDiv(!isSmallDeviceDiv)}>

                            <img src='assets/images/user-profile-header.svg' alt='menu' className='w-21px' />

                        </button>
                    </div>


                    <ul className={isSmallDeviceDiv && width < 993 ? "small-device-profile" : "nav small-profile-div"} aria-labelledby="messageDropdown" x-placement="bottom-end">



                        <li key="li1" className="nav-item">


                            <a className="nav-link d-flex align-items-center usericon-container flew-row">
                                <Avatar size={45} icon={<UserOutlined />} />
                                <div className="d-flex header-list-item">
                                    <span className="ml-2 small-elepsis-text font-size-13" >{name}</span>
                                    {Profile && Profile.length && Profile[0].resource.extension ?

                                        <span className="ml-2 font-size-12">NHI:{Profile[0].resource.extension[3].valueString ? Profile[0].resource.extension[3].valueString : ""} </span>

                                        : null}
                                    <span className={"ml-2 font-size-12 "} onClick={() => his("/accounts")}><strong>Balance: <span className={(Balance && Balance?.toLowerCase()?.indexOf("dr") >= 0 ? "text-danger" : "")}>{Balance}</span></strong></span>
                                    {/* <span className="ml-3">NHI: ZZZ0016</span> */}
                                </div>
                            </a>
                        </li>

                        <li key="li2" className="nav-item  patient-view">
                            <a className="nav-link d-flex small-zero-padding header-font-style flex-column">


                                <span className="align-items-center mb-2 small-direction small-device-padding">
                                    <div className='patient-details-class'>
                                        {/* <i className="mdi mdi-18px  mdi-cellphone-iphone mr-2"></i> */}
                                        <img src='assets/images/Telephone.svg' className='mr-2' />
                                    </div>
                                    <span className="small-left-padding small-elepsis-text small-elepsis-text text-primary-14">
                                        {Profile && Profile.length > 0 && Profile[0]?.resource?.telecom?.find(x => x.use == "mobile")?.value}
                                    </span>
                                </span>
                                <span className="align-items-center  small-direction small-device-padding">
                                    <div className='patient-details-class'>
                                        {/* <i className="mdi mdi-18px mdi-email mr-2"></i> */}
                                        <img src='assets/images/email.svg' className='mr-2' />

                                    </div>
                                    <span className="small-left-padding small-elepsis-text small-elepsis-text">
                                        {email}
                                    </span>
                                </span>
                            </a>
                        </li>
                        <li key="li3" className="nav-item  patient-view">


                            <a className="nav-link d-flex small-zero-padding header-font-style flex-column">

                                <span className='align-items-center mb-2 small-direction small-device-padding'>
                                    <div className='patient-details-class'>
                                        {/* <i className="mdi mdi-20px mdi-home-map-marker mr-2"></i> */}
                                        <img src='assets/images/Location.svg' className='mr-2' />
                                    </div>
                                    <span className="small-left-padding small-elepsis-text text-primary-14">
                                        {
                                            typeof (Practise) !== 'undefined' && Practise.length ?
                                                <span>{Practise[0]?.resource?.extension?.find(o => o.url === "IsPortalPatientLocation")?.valueBoolean ? Practise[0]?.resource?.extension?.find(o => o.url === "LocationName")?.valueString : Practise[0].resource.name}</span>

                                                : <></>}
                                    </span>
                                </span>



                                {Practise && Practise.length > 0 && !Practise[0]?.resource?.extension[6]?.valueBoolean ? <span className="align-items-center small-direction small-device-padding">
                                    <div className='patient-details-class'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="47.693" height="47.693" viewBox="0 0 47.693 47.693">
                                        
                                            <g id="Clock" transform="translate(188 -243)">
                                                <path id="Path_3943" data-name="Path 3943" d="M24.847,1A23.847,23.847,0,1,0,48.693,24.847,23.874,23.874,0,0,0,24.847,1ZM5.769,26.436H4.18a1.59,1.59,0,1,1,0-3.18h1.59a1.59,1.59,0,1,1,0,3.18ZM23.257,4.18a1.59,1.59,0,1,1,3.18,0v1.59a1.59,1.59,0,1,1-3.18,0ZM12.481,39.46l-1.124,1.124a1.59,1.59,0,1,1-2.248-2.248l1.124-1.124a1.59,1.59,0,0,1,2.248,2.248Zm0-26.98a1.585,1.585,0,0,1-2.248,0L9.109,11.356a1.59,1.59,0,0,1,2.248-2.248l1.124,1.124a1.588,1.588,0,0,1,0,2.248ZM26.436,45.512a1.59,1.59,0,1,1-3.18,0v-1.59a1.59,1.59,0,0,1,3.18,0ZM32.33,32.328a1.585,1.585,0,0,1-2.248,0l-6.359-6.359a1.59,1.59,0,0,1-.466-1.124V15.306a1.59,1.59,0,1,1,3.18,0v8.881L32.33,30.08a1.588,1.588,0,0,1,0,2.248Zm8.254,8.254a1.585,1.585,0,0,1-2.248,0l-1.124-1.124A1.59,1.59,0,0,1,39.46,37.21l1.124,1.124a1.588,1.588,0,0,1,0,2.248Zm0-29.228L39.46,12.478a1.59,1.59,0,1,1-2.248-2.248l1.124-1.124a1.59,1.59,0,1,1,2.248,2.248Zm4.93,15.079h-1.59a1.59,1.59,0,1,1,0-3.18h1.59a1.59,1.59,0,1,1,0,3.18Z" transform="translate(-189 242)" fill="#656565" />
                                            </g>
                                        </svg> */}
                                        <img src="assets/images/Clock.svg" className='mr-2' />
                                    </div>
                                    <span className="small-left-padding small-elepsis-text text-primary-14">
                                        {
                                            typeof (Practise) !== 'undefined' && Practise.length ?
                                                //     setTypeId<span className="ml-3">{displayTime(new Date(Practise[0].resource.extension[0].valueDateTime))} - {displayTime(new Date(Practise[0].resource.extension[1].valueDateTime))}</span>
                                                <span >{FormatAMPM(Practise[0].resource.extension[0].valueDateTime.split('+')[0])} - {FormatAMPM(Practise[0].resource.extension[1].valueDateTime.split('+')[0])}</span>

                                                : <></>}
                                    </span>
                                </span> : null}
                            </a>


                        </li>

                    </ul>
                    <ul className="nav header-right-nav justify-content-end" >

                        {/* <li key="li7" className="nav-item dropdown d-flex align-item-center ">
                            {!appSettings.EnableRepeatRxInvoicing && <Tooltip title="Repeat Invoicing flow enabled"><span className={`repeat-invoicing-indicator ${Proxy && Proxy.length > 1 ? "" : "margin-x-15"}`}>Rx</span></Tooltip>}


                        </li> */}

                        {Proxy && Proxy.length > 1 && <li key="li6" className="nav-item dropdown  d-flex d-md-flex align-item-center mt-1">
                            <a className="nav-link nav-link-switch" href="#" id="messageDropdown" data-toggle="dropdown" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" className='mx-2' width="30" height="30" viewBox="0 0 512.009 512">
                                    <g id="Transfer_switch_-_Free_people_icons-2" data-name="Transfer switch - Free people icons-2" transform="translate(-3 -3)">
                                        <path id="Path_5003" data-name="Path 5003" d="M241.043,78.668A61.793,61.793,0,0,0,179.25,16.875H99.793A61.793,61.793,0,0,0,38,78.668v22.078a26.483,26.483,0,0,0,26.483,26.483H214.561a26.483,26.483,0,0,0,26.483-26.483Z" transform="translate(273.966 108.608)" fill="#195faa" />
                                        <circle id="Ellipse_530" data-name="Ellipse 530" cx="55.172" cy="55.172" r="55.172" transform="translate(358.31 3)" fill="#195faa" />
                                        <path id="Path_5004" data-name="Path 5004" d="M206.043,110.293A61.793,61.793,0,0,0,144.25,48.5H64.793A61.793,61.793,0,0,0,3,110.293v22.069a26.492,26.492,0,0,0,26.483,26.483H179.561a26.483,26.483,0,0,0,26.483-26.483Z" transform="translate(0 356.155)" fill="#195faa" />
                                        <circle id="Ellipse_531" data-name="Ellipse 531" cx="55.172" cy="55.172" r="55.172" transform="translate(49.345 282.164)" fill="#195faa" />
                                        <path id="Path_5005" data-name="Path 5005" d="M6.954,192.175l-.132.6A22.066,22.066,0,0,0,24.45,218.56L67.8,226.743a22.034,22.034,0,0,0,25.777-17.585A141.348,141.348,0,0,1,231.81,94.109,22.034,22.034,0,0,0,253.447,76.18l.009-.026c1.739-9.2,6.338-33.545,8.307-44.067A22.025,22.025,0,0,0,241.106,6l-.132-.009C129.516,1.7,28.555,79.411,6.954,192.175Zm450.225,89.459.132-.6a22.067,22.067,0,0,0-17.629-25.785l-43.352-8.183a22.034,22.034,0,0,0-25.777,17.585A141.348,141.348,0,0,1,232.322,379.7a22.034,22.034,0,0,0-21.636,17.929l-.009.026c-1.739,9.2-6.338,33.545-8.307,44.067a22.025,22.025,0,0,0,20.789,26.094c111.457,4.29,212.418-73.419,234.019-186.183Z" transform="translate(26.934 22.096)" fill="#195faa" />
                                    </g>
                                </svg>

                            </a>
                            <div className="dropdown-menu navbar-dropdown dropdown-menu-right" aria-labelledby="messageDropdown">
                                <div className="dropdown-body pt-0">

                                    {
                                        typeof (Proxy) !== 'undefined' && Proxy.length ? (
                                            <FlatList
                                                data={Proxy}
                                                renderItem={({ item }) => (
                                                    <>
                                                        {
                                                            currentUser != item.resource.id &&
                                                            <div onClick={() => requestFamilyMemberPortalLoginCall(Proxy?.find(o => o.resource.extension[1].valueString === "Self")?.resource.id, item.resource.id, item.resource.name[0].text)}>

                                                                    <div className="dropdown-list proxy-user-li">
                                                                    <div className="image-wrapper">

                                                                            <Avatar className='proxy-user-list-avatar' size={40} icon={<UserOutlined />} />
                                                                        {/* {/ <div className="status-indicator rounded-indicator bg-success" / > /} */}
                                                                    </div>
                                                                    <div className="content-wrapper">
                                                                        <small><strong className="name">{item.resource.name[0].text} </strong></small>
                                                                    </div>

                                                                </div>
                                                                </div>
                                                        }

                                                        {
                                                            currentUser == item.resource.id &&
                                                            <Link to='/profile'>
                                                                    <div className="dropdown-list active-proxy-user-li">
                                                                    <div className="image-wrapper">
                                                                        {/* {/ <img className="profile-img" src={profileplace} alt="profile image" / > /} */}
                                                                            < Avatar className='proxy-user-list-avatar' size={40} icon={<UserOutlined />} />
                                                                        {/* {/ <div className="status-indicator rounded-indicator bg-success" / > /} */}
                                                                    </div>
                                                                    <div className="content-wrapper">
                                                                        <small><strong className="name">{item.resource.name[0].text} </strong></small>
                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        }



                                                    </>

                                                )}
                                                keyExtractor={item => item.resource.id}
                                            />
                                        ) : !isGettlistPrpxy && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                        </View>
                                    }
                                    {/* <div className="dropdown-list">
                                        <div className="image-wrapper">
                                            <img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />
                                            <div className="status-indicator rounded-indicator bg-success" />
                                        </div>
                                        <TouchableOpacity onPress={() => window.open('https://www.dropbox.com/s/usl4993vi0cznza/Portal%20App%20Video.mp4?dl=0', '_blank')}>
                                            <div className="content-wrapper">
                                                <small><strong className="name">User Account </strong></small>
                                            </div>
                                        </TouchableOpacity>
                                    </div>
                                    <div className="dropdown-list">
                                        <div className="image-wrapper">
                                            <img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />
                                            <div className="status-indicator rounded-indicator bg-success" />
                                        </div>
                                        <TouchableOpacity onPress={() => window.open('https://www.dropbox.com/s/usl4993vi0cznza/Portal%20App%20Video.mp4?dl=0', '_blank')}>
                                            <div className="content-wrapper">
                                                <small> <strong className="name">User Account 2</strong></small>
                                            </div>
                                        </TouchableOpacity>
                                    </div>
                                */}
                                </div>
                            </div>

                        </li>}
                        <li key="li7" className="nav-item dropdown d-flex align-item-center ">
                            <a className="nav-link d-flex align-items-center profile-img-container right-profile-icon " href="#" id="messageDropdown" data-toggle="dropdown" aria-expanded="false">
                                <img src='assets/images/logoutpatient.svg' />

                            </a>

                            <div className="dropdown-menu navbar-dropdown dropdown-menu-right" aria-labelledby="messageDropdown">
                                <div className="dropdown-body pt-0">
                                    <Link to='/profile' className="prfile-dropdown-item">
                                        <div className="dropdown-list">
                                            <div className="image-wrapper">
                                                {/* <i className="mdi mdi-24px mdi-account text-primary-blue"></i> */}
                                                <img src="assets/images/user.svg" alt='user-icon' className='w-18' />
                                            </div>
                                            <div className="content-wrapper">
                                                Profile
                                            </div>

                                        </div>
                                    </Link>

                                    <div className="setting-dropdown-item"
                                        onClick={() => {

                                            apiDispatcher(actionAPI.GetAppSettingAPI())

                                            setTimeout(function () {
                                                his("/")
                                                window.location.reload(1);
                                            }, 4000)
                                        }}
                                    >
                                        <div className="dropdown-list">

                                            <div className="image-wrapper">
                                                <img src="assets/images/configsetting.svg" alt='user-icon' className='w-18' />
                                            </div>

                                            <div className="content-wrapper">
                                                Refresh Configurations
                                            </div>

                                        </div>
                                    </div>
                                    <div className="setting-dropdown-item"
                                        onClick={() => {
                                            setIndiciHelpModalVisible(true);
                                        }}
                                    >
                                        <div className="dropdown-list">

                                            <div className="image-wrapper">
                                                <img src="assets/images/Help.svg" alt='user-icon' className='w-18' />
                                            </div>

                                            <div className="content-wrapper">
                                                Indici Help
                                            </div>

                                        </div>

                                    </div>
                                    <div className="prfile-dropdown-item" onClick={() => logoutUser()}>
                                        <div className="dropdown-list">

                                            <div className="image-wrapper">
                                                <img src="assets/images/logout.svg" alt='user-icon' className='w-18' />
                                                {/* <i className="mdi mdi-24px mdi-logout text-primary-blue"></i> */}

                                            </div>

                                            <div className="content-wrapper">
                                                Log Out
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <Modal
                open={indiciHelpModalVisible}
                title="Help"
                closable
                onCancel={() => { setIndiciHelpModalVisible(false) }}
                width={500}
                footer={[
                    <Button type='primary' onClick={() => {
                        handleHelpSubmit()
                    }}>Submit</Button>,
                    <Button type='primary' onClick={() => { setIndiciHelpModalVisible(false) }}>Cancel</Button>,

                ]}>
                <div className='d-flex'>

                    <Input autoComplete="off" status={helpModalSubjectErrMsg !== "" ? "error" : ""} className='mb-2' value={helpModalSubject} onChange={(e) => {
                        setHelpModalSubject(e.target.value);
                        setHelpModalSubjectErrMsg("");
                    }} placeholder='Type Subject' />
                    <span className='mx-1 text-danger'>*</span>
                </div>
                <div className='d-flex'>

                    <Input.TextArea autoComplete="off" status={helpModalBodyErrMsg !== "" ? "error" : ""} className='mb-2' placeholder='Type Message' rows={3} value={helpModalBody} onChange={(e) => {
                        setHelpModalBody(e.target.value);
                        setHelpModalBodyErrMsg("");
                    }} />
                    <span className='mx-1 text-danger'>*</span>

                </div>
                <p className='text-primary m-0 text-justify'>Note: This help service is for myIndici help only, please DO NOT use it for medical advice or prescription requests. Thank you</p>
            </Modal>
            <Modal
                open={logoutConfirmModalOpen}
                title="Logout"
                onOk={() => logoutUser()}
                okText="Yes"
                onCancel={() => setLogoutConfirmModalOpen(false)}
                cancelText="No"
                closable={false}
                maskClosable={false}
            >
                Are you sure you want to log out?

            </Modal>
        </nav>

    );
}
function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        ProfileImage: state.ProfileImage,
        Proxy: state.Proxy,
        ProxyMenu: state.ProxyMenu,

        Practise: state.Practise,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProfile: () => dispatch(fetchProfileFromApi()),
        endUserSession: () => dispatch(LogoutUser()),
        getProxyAccessMenu: () => dispatch(getProxyAccessMenu()),
        getProxy: () => dispatch(getProxyListApi()),
        getProfileImage: (uid) => dispatch(fetchProfileImageFromApi(uid)),
        getPracticeCenterDetail: () => dispatch(fetchPractisefromAPI()),
        getAppSetting: () => dispatch(fetchApplicationSettingFromApi())

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)